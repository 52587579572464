import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { MdEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../app/reducers/Users";

Modal.setAppElement("#root");

function EditUserModal({
  modalIsOpen,
  setIsOpen,
  selectedUser,
  setSelectedUser,
}) {
  const [user, setUser] = useState({
    name: selectedUser.name || "",
    email: selectedUser.email || "",
    role_id: selectedUser.role.id || "",
  });

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setUser({
      name: selectedUser.name,
      email: selectedUser.email,
      role_id: selectedUser.role.id,
    });
  }, [selectedUser]);



  function closeModal() {
    setIsOpen(false);
  }

  const updateUserCall = () => {
    dispatch(
      updateUser({
        id: selectedUser.id,
        body: user,
        token: auth.user.api_token,
      })
    ).unwrap().then(() => {
        setSelectedUser(null);
        closeModal();
      });
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* Display user name email  */}
        {selectedUser && (
          <div className="w-96">
            <label
              htmlFor="name"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Nom utilisateur
            </label>
            <input
              id="name"
              className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              placeholder="James"
              defaultValue={selectedUser.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
            />
            <label
              htmlFor="email"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Email
            </label>
            <div className="relative mb-5 mt-2">
              <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                <MdEmail />
              </div>
              <input
                id="email"
                className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-16 text-sm border-gray-300 rounded border"
                placeholder="test@test.com"
                defaultValue={selectedUser.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </div>
            <label
              htmlFor="expiry"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Date de création
            </label>
            <div className="relative mb-5 mt-2">
              <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-calendar-event"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x="4" y="5" width="16" height="16" rx="2" />
                  <line x1="16" y1="3" x2="16" y2="7" />
                  <line x1="8" y1="3" x2="8" y2="7" />
                  <line x1="4" y1="11" x2="20" y2="11" />
                  <rect x="8" y="15" width="2" height="2" />
                </svg>
              </div>
              <input
                id="expiry"
                className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="MM/YY"
                defaultValue={new Date(
                  selectedUser.created_at
                ).toLocaleDateString("fr-FR")}
                disabled
              />
            </div>
            <label
              htmlFor="cvc"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Role
            </label>
            <div className="relative mb-5 mt-2">
              <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-info-circle"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <line x1="12" y1="8" x2="12.01" y2="8"></line>
                  <polyline points="11 12 12 12 12 16 13 16"></polyline>
                </svg>
              </div>
              <select
                id="role"
                className="mb-8 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                defaultValue={selectedUser.role.id}
                onChange={(e) => setUser({ ...user, role_id: e.target.value })}
              >
                <option value="1">User</option>
                <option value="2">Admin</option>
              </select>
            </div>
            <div className="w-full flex justify-end">
              <button onClick={closeModal} className="mx-2 btn btn-error">
                Annuler
              </button>
              <button
                onClick={() => {
                  updateUserCall();
                }}
                className="mx-2 btn btn-success"
              >
                Valider
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default EditUserModal;
