import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCsvInfo, getExports } from "../../../app/reducers/Export";
import { getAllUsers } from "../../../app/reducers/Users";
import * as XLSX from "xlsx";

function ExportForm() {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    startDate: "",
    endDate: "",
    user: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(getExports(auth.user.api_token));
    dispatch(getAllUsers({ token: auth.user.api_token }));
  }, [dispatch, auth.user.api_token]);

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Depenses");
    // file name is username then date as format dd-mm-yyyy
    XLSX.writeFile(
      wb,
      `${formValues.username}-${formValues.startDate
        .split("-")
        .reverse()
        .join("-")}-${formValues.endDate.split("-").reverse().join("-")}.xlsx`
    );
  };
  const handleExport = (e) => {
    e.preventDefault();

    if (formValues.user === "")
      return setErrors({ error: "Veuillez remplir tous les champs" });

    setErrors({});

    dispatch(getCsvInfo({ data: formValues, token: auth.user.api_token }))
      .unwrap()
      .then((res) => {
        exportToExcel(res.notes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
      <form onSubmit={handleExport}>
        <div className="mb-6">
          <div className="flex gap-2">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="postContent"
              >
                Date de début
              </label>
              <input
                id="postContent"
                name="postContent"
                rows="4"
                type="date"
                required
                max={new Date().toISOString().split("T")[0]}
                className="w-full border-2 rounded-md px-4 py-2 leading-5 transition duration-150 ease-in-out sm:text-sm
          sm:leading-5 resize-none focus:outline-none focus:border-blue-500"
                onChange={(e) =>
                  setFormValues({ ...formValues, startDate: e.target.value })
                }
              />
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="postContent"
              >
                Date de fin
              </label>

              <input
                id="postContent"
                name="postContent"
                rows="4"
                required
                max={new Date().toISOString().split("T")[0]}
                type="date"
                className="w-full border-2 rounded-md px-4 py-2 leading-5 transition duration-150 ease-in-out sm:text-sm
          sm:leading-5 resize-none focus:outline-none focus:border-blue-500"
                onChange={(e) =>
                  setFormValues({ ...formValues, endDate: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div>
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="postContent"
          >
            Utilisateur
          </label>

          <select
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                user: e.target.value,
                username: e.target.options[e.target.selectedIndex].text,
              })
            }
          >
            <option value=""></option>
            {users &&
              users.users &&
              users.users.map((user) => (
                <option value={user.id}>{user.name}</option>
              ))}
          </select>
          {errors.error && (
            <p className="text-red-500 text-xs italic">{errors.error}</p>
          )}
        </div>

        <div className="flex items-center justify-between mt-4">
          <button
            type="submit"
            className="flex justify-center items-center bg-blue-500 hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue text-white py-2 px-4 rounded-md transition duration-300 gap-2"
          >
            Exporter CSV
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 24 24"
              id="send"
              fill="#fff"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
}

export default ExportForm;
