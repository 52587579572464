import React from "react";
import { noteTypes } from "../../utils/utils";
import MobileHeader from "./core/MobileHeader";
import { FaPlane, FaQuestion, FaWifi, FaClock, FaCar, FaParking, FaTaxi } from "react-icons/fa";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { IoIosBed } from "react-icons/io";
import { GiPathDistance } from "react-icons/gi";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { IoRestaurant } from "react-icons/io5";
import { FaMobile } from "react-icons/fa";
import { FaTrain } from "react-icons/fa6";
import { SiTransportforlondon } from "react-icons/si";
import { FaRoad } from "react-icons/fa";
import { Link } from "react-router-dom";

function MobileNoteTypeSelector() {
  const icons = {
    FaPlane,
    FaQuestion,
    FaWifi,
    FaClock,
    FaCar,
    FaParking,
    FaTaxi,
    BsFillFuelPumpFill,
    IoIosBed,
    GiPathDistance,
    BsFillPersonVcardFill,
    IoRestaurant,
    FaMobile,
    FaTrain,
    SiTransportforlondon,
    FaRoad,
  };

  return (
    <>
      <MobileHeader title="Type de notes" />
      <div className="grid grid-cols-3 gap-3 m-2 ">
        {noteTypes.map((noteType, index) => {
          const Icon = icons[noteType.icon];
          return (
            <Link
              to={`/create-note/${noteType.name}`}
              state={{ noteType }}
              key={index}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className="text-center"
                style={{ backgroundColor: `${noteType.couleur}` }}
              >
                <div className="flex flex-col h-full">
                  {Icon && (
                    <Icon className="m-auto h-full mb-4 mt-4 text-white" size={40} />
                  )}
                  <div className="bg-[#ffffff5e] p-2 h-full flex flex-col justify-center">
                    <div>{noteType.generique}</div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default MobileNoteTypeSelector;
