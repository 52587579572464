import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";

function Linear() {
  const notes = useSelector((state) => state.notes);
  const [options, setOptions] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Dépenses de l'année",
        align: "left",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Dépenses",
      data: notes.pagination && notes.pagination.monthly_totals,
    },
  ]);

  useEffect(() => {
    const valueWithMonth =
      notes.pagination &&
      notes.pagination.monthly_totals &&
      notes.pagination.monthly_totals.map((value, index) => {
        return {
          x: new Date(2000, index, 1).toLocaleString("default", {
            month: "long",
          }),
          y: value,
        };
      });
    setSeries([
      {
        name: "Dépenses",
        data: valueWithMonth,
      },
    ]);
  }, [notes.pagination && notes.pagination.monthly_totals]);
  return (
    <>
      <div className="!z-5 relative  flex flex-col rounded-[20px] shadow-sm bg-white bg-clip-border shadow-3xl shadow-shadow-500  !flex-row flex-grow rounded-[20px]">
        <div className="ml-[18px] flex h-[90px] w-auto flex-col p-2 items-center">
          <div className="rounded-full bg-[#F4F7FF] p-3">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="#3b82f6"
                strokeWidth="0"
                viewBox="0 0 24 24"
                className="h-7 w-7"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
              </svg>
            </span>
          </div>
          {/* Current Year */}
          <h1 className="text-xl font-bold text-navy-700 mt-2">
            {" "}
            {new Date().getFullYear()}{" "}
          </h1>
          <p className="mt-2 text-sm text-slate-400 font-medium">Dépense</p>
        </div>
        <div className="w-5/6 p-2">
          <Chart options={options} series={series} type="bar" width="95%" />
        </div>
      </div>
    </>
  );
}

export default Linear;
