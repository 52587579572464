import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MobileHeader from "./core/MobileHeader";
import { REACT_APP_STORAGE_URL } from "../../utils/config";
import { deleteNote } from "../../app/reducers/Notes";
import { saveAs } from 'file-saver'

function MobileNoteViewer() {
  let { noteid } = useParams();
  const notes = useSelector((state) => state.notes);
  const note = notes.notes.filter((note) => note.id === parseInt(noteid))[0];
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const deleteNoteCall = (id) => {
    dispatch(
      deleteNote({
        id: id,
        token: auth.user.api_token,
      })
    )
      .unwrap()
      .then(() => {
        window.location.href = "/";
      });
  };

  const showModal = () => {
    const modal = document.getElementById("hs-pro-dupfmsh");
    if (modal) {
      // if modal has hidden class then remove it
      if (modal.classList.contains("hidden")) {
        modal.classList.remove("hidden");
      } else {
        modal.classList.add("hidden");
      }
    }
  };

  return (
    <div>
      <MobileHeader title={note?.Entreprise} />
      <div className="p-5 md:p-8 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
        {/* Title */}
        <ul className="mt-5">
          {/* List Item */}
          <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
            <div className="col-span-1">
              <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                Utilisateur:
              </h4>
            </div>
            <div className="col-span-2">
              <p>
                <a className="text-sm text-blue-600 decoration-2 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-400 dark:hover:text-blue-500">
                  {note.user.name}
                </a>
              </p>
            </div>
          </li>
          <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
            <div className="col-span-1">
              <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                Prix:
              </h4>
            </div>
            <div className="col-span-2">
              <p className="text-sm text-gray-800 dark:text-neutral-200">
                {note.MontantDepense} €
              </p>
            </div>
          </li>
          <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
            <div className="col-span-1">
              <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                Paiement:
              </h4>
            </div>
            <div className="col-span-2">
              <p className="text-sm text-gray-800 dark:text-neutral-200">
                {note.MoyenPaiement}
              </p>
            </div>
          </li>
          <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
            <div className="col-span-1">
              <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                Status:
              </h4>
            </div>
            <div className="col-span-2">
              {/*
              <p className="text-sm text-gray-800 dark:text-neutral-200">
                {note.refunds.status}
              </p> */}
              {/* Change color */}
              {note.refunds.status === 1 ? (
                <p className="text-sm text-red-500 dark:text-red-500">
                  {note.refunds.status}
                </p>
              ) : note.refunds.status === 2 ? (
                <p className="text-sm text-yellow-500 dark:text-yellow-500">
                  {note.refunds.status}
                </p>
              ) : (
                <p className="text-sm text-green-500 dark:text-green-500">
                  {note.refunds.status}
                </p>
              )}
            </div>
          </li>
          {/* Adresse, FOurnisseur gestion,invite,Tva,TypeDepense,Date de creation */}
          {note?.Adresse && (
            <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
              <div className="col-span-1">
                <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                  Adresse:
                </h4>
              </div>
              <div className="col-span-2">
                <p className="text-sm text-gray-800 dark:text-neutral-200">
                  {note?.Adresse}
                </p>
              </div>
            </li>
          )}
          {note?.Gestion && (
            <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
              <div className="col-span-1">
                <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                  Gestion:
                </h4>
              </div>
              <div className="col-span-2">
                <p className="text-sm text-gray-800 dark:text-neutral-200">
                  {note?.Gestion}
                </p>
              </div>
            </li>
          )}
          {note?.Invite && (
            <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
              <div className="col-span-1">
                <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                  Invite:
                </h4>
              </div>
              <div className="col-span-2">
                <p className="text-sm text-gray-800 dark:text-neutral-200">
                  {note?.Invite}
                </p>
              </div>
            </li>
          )}
          {note?.Tva && (
            <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
              <div className="col-span-1">
                <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                  Tva:
                </h4>
              </div>
              <div className="col-span-2">
                <p className="text-sm text-gray-800 dark:text-neutral-200">
                  {note?.Tva}
                </p>
              </div>
            </li>
          )}
          {note?.TypeDepense && (
            <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
              <div className="col-span-1">
                <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                  Type de Depense:
                </h4>
              </div>
              <div className="col-span-2">
                <p className="text-sm text-gray-800 dark:text-neutral-200">
                  {note?.TypeDepense}
                </p>
              </div>
            </li>
          )}
          <li className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
            <div className="col-span-1">
              <h4 className="text-sm text-gray-500 dark:text-neutral-500">
                Date de creation:
              </h4>
            </div>
            <div className="col-span-2">
              <p className="text-sm text-gray-800 dark:text-neutral-200">
                {new Date(note.created_at).toLocaleDateString("fr-FR")}
              </p>
            </div>
          </li>
        </ul>
        <form>
          <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200  dark:border-neutral-700">
            <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5 mt-1">
              <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                <label className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                Enseigne de l’achat
                </label>
              </div>
              {/* End Col */}
              <div className="sm:col-span-8 xl:col-span-6 2xl:col-span-4">
                {/* List Group */}
                <ul className="flex flex-col bg-white border border-gray-200 rounded-xl -space-y-px dark:bg-neutral-800 dark:border-neutral-700">
                  {/* List Item */}
                  <li className="p-4 border-t border-gray-200 first:border-t-0 dark:border-neutral-700">
                    <div className="flex gap-x-3">
                      <svg
                        className="flex-shrink-0 mt-1 w-8 h-8"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.0355 1.75926C10.6408 1.75926 5.30597 1.49951 0.0111241 1C-0.288584 7.23393 5.50578 13.1282 12.7987 14.5668L13.9975 14.7266C14.3372 12.4289 15.9956 3.7773 16.595 1.73928C16.4152 1.75926 16.2353 1.75926 16.0355 1.75926Z"
                          fill="#A49DFF"
                        />
                        <path
                          d="M16.615 1.75926C16.615 1.75926 25.2266 7.9932 28.5234 16.3451C30.0419 11.3499 31.1608 6.15498 32 1C26.8051 1.49951 21.71 1.75926 16.615 1.75926Z"
                          fill="#28289A"
                        />
                        <path
                          d="M13.9975 14.7466L13.8177 15.9455C13.8177 15.9455 12.2592 28.4133 23.1886 31.9699C25.2266 26.8748 27.0049 21.6599 28.5234 16.3251C21.9698 15.8456 13.9975 14.7466 13.9975 14.7466Z"
                          fill="#5ADCEE"
                        />
                        <path
                          d="M16.6149 1.75927C16.0155 3.79729 14.3571 12.4089 14.0175 14.7466C14.0175 14.7466 21.9897 15.8456 28.5233 16.3251C25.1866 7.9932 16.6149 1.75927 16.6149 1.75927Z"
                          fill="#7878FF"
                        />
                      </svg>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                          <a
                            className="text-sm text-blue-600 decoration-2 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-400 dark:hover:text-blue-500"
                            href="#"
                          >
                            {note.Entreprise}
                          </a>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Export */}
          <div className="py-2 sm:py-6 space-y-5 dark:border-neutral-700 border-t border-gray-200">
            <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
              <div className="sm:col-span-8 xl:col-span-6 2xl:col-span-4">
                {/* List Group */}
                <div className="mt-5">
                  {/* Header Grid */}
                  <div className="hidden md:grid md:grid-cols-12 md:gap-6 py-2">
                    <div className="col-span-5">
                      <h5 className="text-sm uppercase text-gray-500 dark:text-neutral-500">
                        Type
                      </h5>
                    </div>
                    {/* End Col */}
                    <div className="col-span-3">
                      <h5 className="text-sm uppercase text-gray-500 dark:text-neutral-500">
                        Date
                      </h5>
                    </div>
                    {/* End Col */}
                    <div className="col-span-3"></div>
                    {/* End Col */}
                    <div className="col-span-1"></div>
                    {/* End Col */}
                  </div>
                  {/* End Header Grid */}
                  {/* List */}
                  <ul className="grid md:grid-cols-12 md:items-center gap-2 md:gap-6 py-3 dark:border-neutral-700">
                    {/* Item */}
                    <li className="md:col-span-5">
                      <div className="flex md:block gap-x-2">
                        <span className="md:hidden min-w-[100px] text-sm text-gray-600 dark:text-neutral-400">
                          Type:
                        </span>
                        <p className="text-sm font-medium text-gray-800 dark:text-neutral-200">
                          PNG
                        </p>
                      </div>
                    </li>
                    {/* End Item */}
                    {/* Item */}
                    <li className="col-span-3">
                      <div className="flex md:block gap-x-2">
                        <span className="md:hidden min-w-[100px] text-sm text-gray-600 dark:text-neutral-400">
                          Date:
                        </span>
                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                          {new Date(note.created_at).toLocaleDateString(
                            "fr-FR"
                          )}
                        </p>
                      </div>
                    </li>
                    {/* End Item */}

                    {/* End Item */}
                    {/* Item */}
                    <li className="col-span-1">
                      <div className="flex md:block gap-x-2">
                        <span className="md:hidden min-w-[100px] text-sm text-gray-600 dark:text-neutral-400">
                          Actions:
                        </span>

                        {/* Visualiser */}
                        <div className="flex flex-col">
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                            onClick={showModal}
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <rect
                                x={3}
                                y={3}
                                width={18}
                                height={18}
                                rx={2}
                                ry={2}
                              />
                              <circle cx={8.5} cy={8.5} r={1.5} />
                              <polyline points="21 15 16 10 5 21" />
                            </svg>
                            Visualiser
                          </button>
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                            onClick={() => {
                              saveAs(`${REACT_APP_STORAGE_URL}/${note.PathImageNote}`, note.Entreprise)
                            }}
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                              <polyline points="7 10 12 15 17 10" />
                              <line x1={12} x2={12} y1={15} y2={3} />
                            </svg>
                            Télécharger
                          </button>
                        </div>
                      </div>
                    </li>
                    {/* End Item */}
                  </ul>
                  {/* End List */}
                </div>
              </div>
            </div>
          </div>
          {/* Danger Zone */}
          <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 dark:border-neutral-700">
            {/* Grid */}
            <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
              <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                <label className="inline-block text-sm text-gray-500 dark:text-neutral-500">
                  Zone de danger
                </label>
              </div>
              {/* End Col */}
              <div className="sm:col-span-8 xl:col-span-6 2xl:col-span-4">
                <button
                  type="button"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-red-500 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-red-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                  onClick={() => deleteNoteCall(note.id)}
                >
                  Supprimer la note
                </button>
                <p className="mt-3 text-sm text-gray-500 dark:text-neutral-500">
                  Vous allez supprimer cette note.
                  <br />
                  Cette action est irréversible.
                </p>
              </div>
              {/* End Col */}
            </div>
            {/* End Grid */}
          </div>
          {/* End Danger Zone */}
        </form>
        {/* End Form */}
      </div>
      {/* Modal */}
      <div
        id="hs-pro-dupfmsh"
        className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none [--close-when-click-inside:true] dark:hs-overlay-backdrop-open:bg-neutral-900/90"
      >
        {/* Background grey */}
        <div className="absolute inset-0 bg-gray-500 opacity-80 dark:bg-neutral-900/90"></div>
        {/* Modal */}
        <div className="mt-7 opacity-100 duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-md sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="relative max-h-full w-full overflow-hidden flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-800 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]">
            {/* Header */}
            <div className="p-4 flex justify-between items-center border-b border-gray-200 dark:border-neutral-700">
              <button
                className="font-semibold text-gray-800 dark:text-neutral-200"
                onClick={showModal}
              >
                Fermer
              </button>
            </div>
            {/* Show note image */}
            <div className="overflow-auto">
              <img
                src={`${REACT_APP_STORAGE_URL}/${note.PathImageNote}`}
                alt="note"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNoteViewer;
