import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function ExportTable() {
  const exports = useSelector((state) => state.export);
  const users = useSelector((state) => state.users);

  return (
    <div className="overflow-hidden border border-gray-200 bg-white rounded-[20px]">
      <div className="overflow-x-auto">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th>Utilisateur</th>
              <th>Date Début</th>
              <th>Date Fin</th>
              <th>Format</th>
            </tr>
          </thead>
          <tbody>
            {exports && exports.exports && exports.exports.length > 0 ? (
             exports.exports.slice(0, 10).map((exportItem) => (
                <tr key={exportItem.id}>
                  <td>
                    {users && users.users && users.users.length > 0 ? (
                      users.users.map((user) => {
                        if (user.id == exportItem.user_id) {
                          return `${user.name}`;
                        }
                      })
                    ) : (
                      <span className="text-red-500">Utilisateur supprimé</span>
                    )}

                </td>
                  <td>{exportItem.startDate}</td>
                  <td>{exportItem.endDate}</td>
                  <td>{exportItem.export_format}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  Pas de data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ExportTable;
