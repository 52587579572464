import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotesByPage, updateNote } from "../../app/reducers/Notes";
import { REACT_APP_STORAGE_URL } from "../../utils/config";
import { FaFilePdf } from 'react-icons/fa';

function EditNotesModal({ selectedNote, setSelectedNote }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const notes = useSelector((state) => state.notes);
  const [isImageEnlarged, setIsImageEnlarged] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isPDF, setIsPDF] = useState(false);
  const [refundStatusId, setRefundStatusId] = useState(2); // Défaut à "En cours"
  const [montantHT, setMontantHT] = useState("0.00");

  const gestionCodes = useSelector((state) => state.gestionCodes.codes);

  const calculTTC = (montantHT, tva) => {
    const montantHTNumber = parseFloat(montantHT);
    const tvaNumber = parseFloat(tva);
    if (isNaN(montantHTNumber) || isNaN(tvaNumber)) {
      return "0.00";
    }
    return (montantHTNumber * (1 + tvaNumber / 100)).toFixed(2);
  };

  useEffect(() => {
    if (selectedNote) {
      const calculatedTTC = calculTTC(
        selectedNote.MontantDepense,
        selectedNote.Tva
      );
      setMontantHT(calculatedTTC);
    }
  }, [selectedNote]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      if (file.type === 'application/pdf') {
        setIsPDF(true);
        setPreviewUrl(null);
      } else {
        setIsPDF(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setPreviewUrl(null);
      setIsPDF(false);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    if (selectedNote?.PathImageNote) {
      const fileExtension = selectedNote.PathImageNote.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        setIsPDF(true);
        setPreviewUrl(`${REACT_APP_STORAGE_URL}/${selectedNote.PathImageNote}`);
      } else {
        setIsPDF(false);
        setPreviewUrl(`${REACT_APP_STORAGE_URL}/${selectedNote.PathImageNote}`);
      }
    } else {
      setPreviewUrl(null);
      setIsPDF(false);
    }
    setRefundStatusId(selectedNote?.RefundStatusId || 2);
  }, [selectedNote]);

  const clearSelectedNote = () => {
    setSelectedNote(null);
  };

  const closeEditNoteModal = () => {
    clearSelectedNote();
    document.getElementById("modalEditNote").close();
  };

  const updateNoteSubmit = async (e) => {
    e.preventDefault();

    let noteData = {
      id: selectedNote.id,
      TypeDepense: e.target.TypeDepense.value,
      DateDepense: e.target.DateDepense.value,
      Entreprise: e.target.Entreprise.value,
      MontantDepense: e.target.MontantDepense.value,
      MoyenPaiement: e.target.MoyenPaiement.value,
      RefundStatusId: refundStatusId,
      Tva: e.target.Tva.value,
      Adresse: e.target.Adresse.value,
      Fournisseur: e.target.Fournisseur.value,
      Invite: e.target.Invite.value,
      Gestion: e.target.Gestion.value,
    };

    if (selectedFile) {
      const base64Image = await convertToBase64(selectedFile);
      noteData.image = base64Image;
    }

    try {
      await dispatch(
        updateNote({
          note: noteData,
          token: auth.user.api_token,
        })
      ).unwrap();

      clearSelectedNote();
      dispatch(
        getNotesByPage({
          page: 1,
          token: auth.user.api_token,
        })
      );

      document.getElementById("modalEditNote").close();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDownload = () => {
    if (previewUrl) {
      const link = document.createElement("a");
      link.href = previewUrl;
      link.target = "_blank";
      link.download = `file-${selectedNote.id}.${isPDF ? 'pdf' : 'jpg'}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    if (selectedNote) {
      // ... autres initialisations ...
      setRefundStatusId(selectedNote?.RefundStatusId || 2);
      if (!selectedNote.Gestion && gestionCodes.length > 0) {
        setSelectedNote(prevNote => ({
          ...prevNote,
          Gestion: gestionCodes[0].value
        }));
      }
    }
  }, [selectedNote, gestionCodes]);

  return (
    <dialog id="modalEditNote" className="modal">
      {selectedNote && (
        <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md overflow-y-auto max-h-[90vh]">
          <h1 className="text-xl font-bold text-black capitalize dark:text-black mb-4">
            Modification d'une Note
          </h1>
          <form onSubmit={updateNoteSubmit} className="grid grid-cols-2 gap-4">
            <div>
              <label
                className="text-black dark:text-black"
                htmlFor="TypeDepense"
              >
                Type
              </label>
              <input
                id="TypeDepense"
                name="TypeDepense"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md text-black dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.TypeDepense}
              />
            </div>

            <div>
              <label
                className="text-black dark:text-black"
                htmlFor="DateDepense"
              >
                Date de Depense
              </label>
              <input
                id="DateDepense"
                name="DateDepense"
                type="date"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.DateDepense}
              />
            </div>

            <div>
              <label
                className="text-black dark:text-black"
                htmlFor="Enseigne de l'achat"
              >
                Enseigne de l'achat
              </label>
              <input
                id="Entreprise"
                name="Entreprise"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.Entreprise}
              />
            </div>
            <div>
              <label
                className="text-black dark:text-black"
                htmlFor="MoyenPaiement"
              >
                MoyenPaiement
              </label>
              <select
                id="MoyenPaiement"
                name="MoyenPaiement"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md text-black dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.MoyenPaiement}
              >
                <option value="Carte">Carte</option>
                <option value="Espece">Espèce</option>
              </select>
            </div>

            <div>
              <label
                className="text-black dark:text-black"
                htmlFor="RefundStatusId"
              >
                Statut de remboursement
              </label>
              <select
                id="RefundStatusId"
                name="RefundStatusId"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md text-black dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                value={refundStatusId}
                onChange={(e) => setRefundStatusId(Number(e.target.value))}
                style={
                  refundStatusId === 1
                    ? { backgroundColor: "#F87171" }
                    : refundStatusId === 2
                    ? { backgroundColor: "#FBBF24" }
                    : { backgroundColor: "#34D399" }
                }
              >
                <option value={1} className="bg-white">
                  Refusé
                </option>
                <option value={2} className="bg-white">
                  En cours
                </option>
                <option value={3} className="bg-white">
                  Remboursé
                </option>
              </select>
            </div>
            <div>
              <label
                className="text-black dark:text-black"
                htmlFor="MontantDepense"
              >
                Montant TTC
              </label>
              <input
                id="MontantDepense"
                name="MontantDepense"
                type="number"
                step="0.01"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.MontantDepense}
                onChange={(e) => {
                  const newHT = calculTTC(e.target.value, selectedNote.Tva);
                  setMontantHT(newHT);
                }}
              />
            </div>

            <div>
              <label className="text-black dark:text-black" htmlFor="Tva">
                TVA (%)
              </label>
              <input
                id="Tva"
                name="Tva"
                type="number"
                step="0.1"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.Tva}
                onChange={(e) => {
                  const newTTC = calculTTC(
                    selectedNote.MontantDepense,
                    e.target.value
                  );
                  setMontantHT(newTTC);
                }}
              />
            </div>

            <div>
              <label className="text-black dark:text-black" htmlFor="HT">
                Montant HT
              </label>
              <input
                id="HT"
                name="HT"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                value={`${montantHT} €`}
                disabled
              />
            </div>

            <div className="col-span-2">
              <label className="text-black dark:text-black" htmlFor="Adresse">
              CP + Ville
              </label>
              <input
                id="Adresse"
                name="Adresse"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.Adresse}
              />
            </div>

            <div>
              <label
                className="text-black dark:text-black"
                htmlFor="Fournisseur"
              >
                Fournisseur
              </label>
              <input
                id="Fournisseur"
                name="Fournisseur"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.Fournisseur}
              />
            </div>

            <div>
              <label className="text-black dark:text-black" htmlFor="Invite">
                Invités
              </label>
              <input
                id="Invite"
                name="Invite"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.Invite}
              />
            </div>

            <div>
              <label className="text-black dark:text-black" htmlFor="Gestion">
                Gestion
              </label>
              <select
                id="Gestion"
                name="Gestion"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-gray-300 rounded-md dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                defaultValue={selectedNote.Gestion}
              >
                {gestionCodes.length > 0 ? (
                  gestionCodes.map((code) => (
                    <option key={code.id} value={code.value}>
                      {code.value}
                    </option>
                  ))
                ) : (
                  <option value="">Aucune option disponible</option>
                )}
              </select>
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-black">
                Fichier (Image ou PDF)
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  {previewUrl ? (
                    isPDF ? (
                      <div className="mb-4">
                        <FaFilePdf className="mx-auto h-12 w-12 text-red-500" />
                        <p className="mt-2 text-sm text-gray-500">Fichier PDF</p>
                      </div>
                    ) : (
                      <div className="mb-4">
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="mx-auto h-32 w-32 object-cover rounded-md"
                          onClick={() => setIsImageEnlarged(true)}
                        />
                      </div>
                    )
                  ) : (
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <div className="flex justify-center items-center space-x-2">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span className="text-sm text-black hover:text-indigo-400 transition-all">
                        {previewUrl ? "Changer le fichier" : "Télécharger un fichier"}
                      </span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                        accept="image/*,.pdf"
                      />
                    </label>
                    {previewUrl && (
                      <button
                        type="button"
                        onClick={handleDownload}
                        className="px-3 py-1 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Télécharger
                      </button>
                    )}
                  </div>
                  {!previewUrl && (
                    <p className="text-xs text-gray-500">
                      PNG, JPG, GIF, PDF jusqu'à 10MB
                    </p>
                  )}
                </div>
              </div>
              {isImageEnlarged && !isPDF && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="max-w-4xl max-h-full p-4">
                    <img
                      src={previewUrl}
                      alt="Enlarged view"
                      className="max-w-full max-h-full object-contain"
                      style={{ maxHeight: "calc(100vh - 4rem)" }}
                    />
                    <button
                      onClick={() => setIsImageEnlarged(false)}
                      className="absolute top-4 right-4 text-white text-xl bg-gray-800 rounded-full w-8 h-8 flex items-center justify-center"
                    >
                      &times;
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="col-span-2 flex justify-end mt-6">
              <button
                className="text-black transition-colors duration-200 transform btn btn-neutral btn-md px-6 mx-2 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  closeEditNoteModal();
                }}
              >
                Annuler
              </button>
              <button
                className="text-black transition-colors duration-200 transform btn btn-warning btn-md px-6 mx-2"
                disabled={notes.isLoading}
              >
                {notes.isLoading && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    ></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                )}
                Modifier
              </button>
            </div>
            {notes.error && (
              <div className="text-red-500 text-sm mt-2">
                {notes.error.message}
              </div>
            )}
          </form>
        </section>
      )}
    </dialog>
  );
}

export default EditNotesModal;
