import React from "react";
import TablesFilters from "../Filters/TablesFilters";

function TableFilter({ Filter, setFilter }) {
  const modalShowNotes = () => {
    document.getElementById("modalAddNote").showModal();
  };

  return (
    <div className="p-3">
      <h2 className="text-2xl font-semibold leading-tight pb-4">Vos Notes</h2>
      <div className="py-4 grid lg:grid-cols-2 gap-y-2 lg:gap-y-0 lg:gap-x-5 border-y border-gray-200 dark:border-neutral-700">
        <div>
          {/* Search Input */}
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
              <svg
                className="flex-shrink-0 w-4 h-4 text-gray-500 dark:text-neutral-400"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx={11} cy={11} r={8} />
                <path d="m21 21-4.3-4.3" />
              </svg>
            </div>
            <input
              type="text"
              className="py-2 px-3 ps-10 pe-16 block w-full bg-gray-200 xl:bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
              placeholder="Chercher une note avec filtre"
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>
        <div>
          <button onClick={() => modalShowNotes()} className="bg-blue-500 text-white px-4 py-2 rounded-md">Ajouter une note</button>
        </div>
      </div>
    </div>
  );
}

export default TableFilter;
