import React from "react";
import Sidebar from "../SideBar";

function AdminDashboard() {
  return (
    <div className="flex bg-[#f4f7ff] h-full">
      <Sidebar />
      <div className="w-full mx-9">
        <h1 className="text-md mt-4 ">Pages / Main Dashboard</h1>
        <h1 className="text-3xl mt-1 font-bold">Notes Dashboard</h1>
        <div className="my-3 grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-6 3xl:grid-cols-6">Hi</div>
      </div>
    </div>
  );
}

export default AdminDashboard;
