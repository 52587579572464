import React, { useEffect, useState } from "react";
import logo from "../../assets/Images/logo.png";
import logoDark from "../../assets/Images/logo_dark.png";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../../app/reducers/Auth";
import { Link, useNavigate } from "react-router-dom";
import { REACT_APP_VERSION } from "../../utils/config";

// Import logo from assets/Images/Logo_Enterprise
import logo_business from "../../assets/Images/Logo_Entreprises/logo_business.png";
import logo_discount from "../../assets/Images/Logo_Entreprises/logo_discount.png";
import logo_ltf from "../../assets/Images/Logo_Entreprises/logo_ltf.png";
import logo_tomatocart from "../../assets/Images/Logo_Entreprises/logo_tomat.png";
import logo_trial from "../../assets/Images/Logo_Entreprises/logo_trial.png";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();

    // Dispatch the login action with the provided credentials
    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        // If login was successful, redirect to the homepage
        navigate("/");
      });
  };

  return (
    <div className="bg-white flex justify-center items-center h-screen">
      {/* Partie gauche avec le formulaire de connexion */}
      <main className="w-full max-w-md mx-auto p-8 bg-gradient-to-b from-gray-50 to-white rounded-3xl shadow-2xl">
        <div className="space-y-8">
          {/* Logo */}
          <div className="flex items-center justify-center">
            <img src={logo_ltf} alt="Logo" className="w-48 transition-all duration-300 hover:scale-105" />
          </div>

          <form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-all duration-300"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Mot de passe
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-all duration-300"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Se souvenir de moi
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Mot de passe oublié ?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300"
                onClick={handleLogin}
              >
                Se connecter
              </button>
            </div>
          </form>

          <p className="mt-2 text-sm text-gray-600 text-center">
            Vous n'avez pas de compte ?{" "}
            <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
              S'inscrire
            </Link>
          </p>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
              </div>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-3">
              {/* Ajoutez ici des boutons pour d'autres méthodes de connexion si nécessaire */}
            </div>
          </div>
        </div>
      </main>

      {/* Partie droite avec fond flouté et points colorés */}
      <div className="w-1/3 h-screen hidden lg:block relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-blue-700 opacity-75 backdrop-filter backdrop-blur-lg"></div>

        {/* Logos d'entreprise */}
        <div className="relative z-10 flex flex-col justify-center items-center h-full">
          <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-4 rounded-xl">
            <img src={logo_discount} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logo_tomatocart} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logo_trial} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logoDark} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logo_business} alt="Logo" style={{ width: "12rem" }} className="m-auto"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
