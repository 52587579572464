// import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet,Navigate } from "react-router-dom";

const AdminMiddleware = () => {

    let canConnect = true;

	const auth = useSelector((state) => state.auth);

    if(auth.user.role.role !== "admin" || auth.isAuthenticated === false){
        canConnect = false;
    }


	return (  canConnect ? <Outlet /> : <Navigate to="/login" /> );

};

export default AdminMiddleware;
