import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { REACT_APP_API_URL, REACT_APP_STORAGE_URL } from "../../utils/config";
import Sidebar from "../SideBar";
import { useSelector } from "react-redux";
import { AiFillEye } from "react-icons/ai";
import { FaWrench } from "react-icons/fa";
import { BsFillTrashFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";

function AdminUserNotes() {
  const { id } = useParams();
  const auth = useSelector((state) => state.auth);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    fetchNotes();
  }, [id]);

  const fetchNotes = () => {
    axios
      .get(`${REACT_APP_API_URL}/admin/users/${id}/notes`, {
        headers: {
          Authorization: `Bearer ${auth.user.api_token}`,
        },
      })
      .then((response) => {
        setNotes(response.data.notes);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des notes:", error);
      });
  };

  const viewNote = (note) => {
    setSelectedNote(note);
    document.getElementById("modalSeeNote").showModal();
  };

  const editNote = (note) => {
    // Implémentez la logique pour éditer la note
  };

  const deleteNote = (noteId) => {
    if (window.confirm("Voulez-vous supprimer cette note ?")) {
      // Implémentez la logique pour supprimer la note
    }
  };

  return (
    <div className="flex bg-[#f4f7ff] h-full">
      <Sidebar />
      <div className="w-full mx-9">
        <h1 className="text-md mt-4">Pages / Utilisateur notes</h1>
        <h1 className="text-3xl mt-1 font-bold">Notes</h1>
        <div className="overflow-hidden border border-gray-200 rounded-[20px] mt-4">
          <div className="overflow-x-auto">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-white text-gray-700 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Type</th>
                  <th className="py-3 px-6 text-left">Enseigne de l’achat</th>
                  <th className="py-3 px-6 text-center">Montant</th>
                  <th className="py-3 px-6 text-center">Moyen de paiement</th>
                  <th className="py-3 px-6 text-center">Date Dépense</th>
                  <th className="py-3 px-6 text-center">Remboursement</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-medium">
                {notes.map((note) => (
                  <tr className="bg-white border-b border-gray-200 hover:bg-gray-100" key={note.id}>
                    <td className="py-3 px-6 text-left">{note.TypeDepense}</td>
                    <td className="py-3 px-6 text-left">{note.Entreprise}</td>
                    <td className="py-3 px-6 text-center">{note.MontantDepense}</td>
                    <td className="py-3 px-6 text-center">{note.MoyenPaiement}</td>
                    <td className="py-3 px-6 text-center">{note.DateDepense}</td>
                    <td className="py-3 px-6 text-center">{note.RefundStatusId === 1 ? "En attente" : note.RefundStatusId === 2 ? "Accepté" : "Refusé"}</td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center gap-4">
                        <div className="bg-green-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105" onClick={() => viewNote(note)}>
                          <AiFillEye size={20} color="white" />
                        </div>
                        <div className="bg-yellow-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105" onClick={() => editNote(note)}>
                          <FaWrench size={20} color="white" />
                        </div>
                        <div className="bg-red-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105" onClick={() => deleteNote(note.id)}>
                          <BsFillTrashFill size={20} color="white" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <SeeNotesModal selectedNote={selectedNote} />
    </div>
  );
}

function SeeNotesModal({ selectedNote }) {
  const downloadImage = () => {
    if (selectedNote && selectedNote.PathImageNote) {
      window.open(`${REACT_APP_STORAGE_URL}/${selectedNote.PathImageNote}`, '_blank');
    }
  };

  return (
    <dialog id="modalSeeNote" className="modal">
      {selectedNote ? (
        <>
          <div className="modal-box max-w-3xl p-8 bg-gradient-to-br from-blue-50 to-blue-50 rounded-xl shadow-2xl border border-blue-200">
            <h2 className="text-3xl font-bold mb-6 text-blue-800 border-b-2 border-blue-300 pb-2">{selectedNote.Entreprise}</h2>
            <div className="grid grid-cols-2 gap-6 mb-8">
              <InfoItem label="Moyen de paiement" value={selectedNote.MoyenPaiement} />
              <InfoItem label="Date de dépense" value={selectedNote.DateDepense} />
              <InfoItem label="Type de dépense" value={selectedNote.TypeDepense} />
              <InfoItem label="Montant" value={`${selectedNote.MontantDepense} €`} />
              <InfoItem label="TVA" value={selectedNote.Tva} />
              <InfoItem label="Adresse" value={selectedNote.Adresse} />
              <InfoItem label="Fournisseur" value={selectedNote.Fournisseur} />
              <InfoItem label="Invité" value={selectedNote.Invite} />
              <InfoItem label="Gestion" value={selectedNote.Gestion} />
            </div>
            <div className="border-4 border-blue-200 rounded-lg overflow-hidden shadow-lg relative">
              <img
                src={`${REACT_APP_STORAGE_URL}/${selectedNote.PathImageNote}`}
                alt="Note de frais"
                className="w-full object-cover"
              />
              <button
                onClick={downloadImage}
                className="absolute bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center"
              >
                <FiDownload className="mr-2" /> Télécharger
              </button>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button className="btn btn-circle btn-ghost absolute right-4 top-4 text-blue-600 bg-blue-100 hover:bg-blue-200 transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </form>
        </>
      ) : (
        <div className="p-8 text-center text-blue-600 font-semibold">Aucune note sélectionnée</div>
      )}
    </dialog>
  );
}

function InfoItem({ label, value }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md border border-blue-100">
      <span className="font-semibold text-blue-600 block mb-1">{label}</span>
      <span className="text-gray-800">{value}</span>
    </div>
  );
}

export default AdminUserNotes;
