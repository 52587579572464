import React from 'react';
import './App.css';
import RouterManager from './RouterManager'
import RouterMobileManager from './RouterMobileManager'
import { isMobile } from "react-device-detect";
import 'react-toastify/dist/ReactToastify.css';
import "preline/preline";


function App() {
  return (
    <div >
      {isMobile ? <RouterMobileManager /> : <RouterManager />}
    </div>
  );
}

export default App;
