import React, { useEffect } from "react";
import Sidebar from "../SideBar";
import AdminUserList from "./AdminUserList";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../app/reducers/Users";
import EditUserModal from "../Modal/EditUserModal";

function AdminUserPage() {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.user.api_token) return;
    dispatch(getAllUsers({ token: auth.user.api_token }))
      .unwrap()
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, auth.user.api_token]);

  return (
    <div className="flex bg-[#f4f7ff] h-full">
      <Sidebar />
      <div className="w-full mx-9">
        <h1 className="text-md mt-4 ">Admin / Page Utilisateurs</h1>
        <h1 className="text-3xl mt-1 font-bold">Gestion Utilisateurs</h1>

        {users && users.users && users.users.length > 0 && <AdminUserList />}
      </div>
    </div>
  );
}

export default AdminUserPage;
