import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../utils/config";

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/admin/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      });

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }

      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_URL}/admin/users/${data.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          body: JSON.stringify(data.body),
        }
      );

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }

      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_URL}/admin/users/${data.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      );

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }

      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const UsersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    pagination: {},
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getAllUsers.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.status = "success";
      state.users = action.payload.users.data;
      state.pagination = action.payload.users;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [deleteUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.status = "success";
      state.users = state.users.filter((user) => user.id !== action.payload.id);
    },
    [deleteUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [updateUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateUser.fulfilled]: (state, action) => {
      state.status = "success";
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? {...action.payload.user, total_notes: user.total_notes} : user
      );
    },
    [updateUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

  },
});

export const {} = UsersSlice.actions;

export default UsersSlice.reducer;
