import React, { useEffect, useState } from "react";
import Sidebar from "../SideBar";
import NotesTable from "./NotesTable";
import CardStats from "./CardStats";
import { isMobile } from "react-device-detect";
import { getNotes } from "../../app/reducers/Notes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/reducers/Auth";
import SeeNotesModal from "../Modal/SeeNotesModal";
import EditNotesModal from "../Modal/EditNotesModal";
import NotesTableMobile from "../Mobile/Dashboard/NotesTableMobile";
import GlobalSearchBar from "../GlobalSearchBar";
import Linear from "./Graph/Linear";
import NotesTracking from "./NotesTracking";
import AddNotesModal from "../Modal/AddNotesModal";
import { getGestionCodes } from "../../app/reducers/GestionCodes";

function Dashboard() {
  const [showSearchBar, setSearchBar] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const notes = useSelector((state) => state.notes);
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    dispatch(getNotes(auth.user.api_token))
      .unwrap()
      .catch((err) => {
        dispatch(logout());
      });

    dispatch(getGestionCodes(auth.user.api_token));
  }, [dispatch, auth.user.api_token]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.code === "Space") {
        setSearchBar(!showSearchBar);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showSearchBar]);

  return (
    <div>
      <SeeNotesModal selectedNote={selectedNote} />
      <EditNotesModal selectedNote={selectedNote} setSelectedNote={setSelectedNote} />
      <AddNotesModal />

      {showSearchBar && (
        <GlobalSearchBar
          showSearchBar={showSearchBar}
          setSearchBar={setSearchBar}
        />
      )}

      {!isMobile && (
        <>
          <div className="flex bg-[#f4f7ff] h-full">
            <Sidebar />
            <div className="w-full mx-9">
              <h1 className="text-md mt-4 ">Pages / Main Dashboard</h1>
              <h1 className="text-3xl mt-1 font-bold">Notes Dashboard</h1>
              <div className="my-3 grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-6 3xl:grid-cols-6">
                {/* get current month name like fevrier to the value */}
                <CardStats
                  name={new Date().toLocaleString("default", { month: "long" })}
                  value={"€" + (notes.notes && notes.pagination.total_price)}
                  type="number"
                />
                <CardStats
                  name={"Prix total"}
                  value={"€" + (notes.notes && notes.pagination.total_price)}
                  type="number"
                />
                <CardStats
                  name={"Note max"}
                  value={"€" + (notes.notes && notes.pagination.note_max)}
                  type="number"
                />
                <CardStats
                  name={"Note min"}
                  value={"€" + (notes.notes && notes.pagination.note_min)}
                  type="number"
                />
                 <CardStats
                  name={"Notes Remboursées"}
                  value={(notes.notes && notes.pagination.note_refunds)}
                  type="text"
                />
                <CardStats
                  name={"Notes Refusées"}
                  value={(notes.notes && notes.pagination.note_non_refunds)}
                  type="text"
                />
              </div>
              <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                <Linear />
                <NotesTracking />
              </div>
              <div className="mt-5">
                <NotesTable
                  selectedNote={selectedNote}
                  setSelectedNote={setSelectedNote}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;
