import React from "react";
import { useDispatch, useSelector } from "react-redux";

function NotesTracking() {
  const notes = useSelector((state) => state.notes);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  return (
    <div>
      <div className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500    w-full h-full px-6 pb-6 sm:overflow-x-auto">
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 ">Status Notes</div>
          <div className="relative flex">
            <div className="flex">
              <button className="flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100     linear justify-center rounded-lg font-bold transition duration-200">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  className="h-6 w-6"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
          <table role="table" className="w-full">
            <thead>
              <tr role="row">
                <th
                  colSpan="1"
                  role="columnheader"
                  title="Toggle SortBy"
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start "
                >
                  <p className="text-xs tracking-wide text-gray-600">NAME</p>
                </th>
                <th
                  colSpan="1"
                  role="columnheader"
                  title="Toggle SortBy"
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start "
                >
                  <p className="text-xs tracking-wide text-gray-600">STATUS</p>
                </th>
                <th
                  colSpan="1"
                  role="columnheader"
                  title="Toggle SortBy"
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start "
                >
                  <p className="text-xs tracking-wide text-gray-600">DATE</p>
                </th>
                <th
                  colSpan="1"
                  role="columnheader"
                  title="Toggle SortBy"
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start "
                >
                  <p className="text-xs tracking-wide text-gray-600">PRIX</p>
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              {notes &&
                notes.notes.slice(0, 4).map((note, index) => (
                  <tr role="row" key={index}>
                    <td
                      className="pt-[14px] pb-[18px] sm:text-[14px]"
                      role="cell"
                    >
                      <p className="text-sm font-bold text-navy-700 ">
                        {note?.Entreprise}
                      </p>
                    </td>
                    <td
                      className="pt-[14px] pb-[18px] sm:text-[14px]"
                      role="cell"
                    >
                      <div className="flex items-center gap-2">
                        {/* Color depends on if refunds.status is 1 or 2 or 3 */}
                        <p
                          className={`text-sm font-bold text-navy-700 note-status-${note?.RefundStatusId} p-1 rounded-lg`}
                        >
                          {note?.refunds?.status}
                        </p>
                      </div>
                    </td>
                    <td
                      className="pt-[14px] pb-[18px] sm:text-[14px]"
                      role="cell"
                    >
                      <p className="text-sm font-bold text-navy-700 ">
                        {note?.DateDepense}
                      </p>
                    </td>
                    <td
                      className="pt-[14px] pb-[18px] sm:text-[14px]"
                      role="cell"
                    >
                      <h1 className="font-bold">{note?.MontantDepense} €</h1>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NotesTracking;
