import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from "./reducers/Auth";
import NotesReducer from "./reducers/Notes";
import UsersReducer from "./reducers/Users";
import ExportReducer from "./reducers/Export";
import GestionCodesReducer from "./reducers/GestionCodes";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import thunk from "redux-thunk";
import persistCombineReducers from "redux-persist/es/persistCombineReducers";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
	key: "AquaNotesAuth",
	storage,
	whitelist: ["auth","notes","gestionCodes"]
};

const rootReducer = persistCombineReducers(persistConfig, {
	auth: AuthReducer,
	notes: NotesReducer,
	users: UsersReducer,
	export: ExportReducer,
	gestionCodes: GestionCodesReducer
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk, logger],
});

export const persistor = persistStore(store);
