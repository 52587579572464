import React from "react";
import { useSelector } from "react-redux";

function CardStats({name, value, icon,type}) {
  const notes = useSelector((state) => state.notes);

  // Fonction pour formater la valeur à 2 décimales
  const formatValue = (val) => {
    if (val === null || val === undefined) return '0.00';

    let numericValue;
    if (typeof val === 'string') {
      // Si c'est une chaîne, on enlève le symbole € s'il est présent
      numericValue = parseFloat(val.replace('€', ''));
    } else if (typeof val === 'number') {
      // Si c'est déjà un nombre, on l'utilise directement
      numericValue = val;
    } else {
      // Pour tout autre type, on retourne '0.00'
      return '0.00';
    }

    return isNaN(numericValue) ? '0.00' : numericValue.toFixed(2);
  };

  return (
    <div className="!z-5 relative flex flex-col rounded-[20px] shadow-sm bg-white bg-clip-border shadow-3xl shadow-shadow-500  !flex-row flex-grow items-center rounded-[20px]">
    <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
      <div className="rounded-full bg-[#F4F7FF] p-3">
        <span className="flex items-center text-brand-500 ">
          <svg
            stroke="currentColor"
            fill="#3b82f6"
            strokeWidth="0"
            viewBox="0 0 24 24"
            className="h-7 w-7"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
          </svg>
        </span>
      </div>
    </div>
    <div className="h-50 ml-4 flex w-auto flex-col justify-center">
      <p className="font-dm text-sm font-medium text-[#b0b3bd]">
        {name}
      </p>
      <h4 className="text-xl font-bold text-navy-700 ">
        {type === "number" ? `€${formatValue(value)}` : value}
      </h4>
    </div>
  </div>
  );
}

export default CardStats;
