import React, { useState, useEffect } from "react";
import Sidebar from "../SideBar";
import { useDispatch, useSelector } from "react-redux";
import { addGestionCode, getGestionCodes, deleteGestionCode } from "../../app/reducers/GestionCodes";

function AdminGestion() {

  const gestionCodes = useSelector((state) => state.gestionCodes.codes);
  const isLoading = useSelector((state) => state.gestionCodes.isLoading);
  const token = useSelector((state) => state.auth.user.api_token);
  const dispatch = useDispatch();

  const [newCode, setNewCode] = useState("");

  useEffect(() => {
    dispatch(getGestionCodes(token));
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addGestionCode({ value: newCode, token }));
    setNewCode("");
  };

  const handleDelete = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce code de gestion ?")) {
      dispatch(deleteGestionCode({ id, token }));
    }
  };

  return (
    <div className="flex bg-[#f4f7ff] h-full">
      <Sidebar />
      <div className="w-full mx-9">
        <h1 className="text-md mt-4 ">Pages / Gestion</h1>
        <h1 className="text-3xl mt-1 font-bold">Gestion Administratif</h1>

        {/* Formulaire pour ajouter un code de gestion */}
        <form onSubmit={handleSubmit} className="my-6">
          <input
            type="text"
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            placeholder="Nouveau code de gestion"
            className="p-2 border rounded mr-2"
            disabled={isLoading}
          />
          <button
            type="submit"
            className={`bg-blue-500 text-white p-2 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin h-5 w-5 mr-3 inline-block" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Ajout en cours...
              </>
            ) : (
              "Ajouter"
            )}
          </button>
        </form>

        {/* Tableau des codes de gestion */}
        <table className="w-full border-collapse border bg-white">
          <thead>
            <tr className="bg-white">
              <th className="border p-2 w-1/6">ID</th>
              <th className="border p-2 w-4/6">Code</th>
              <th className="border p-2 w-1/6">Actions</th>
            </tr>
          </thead>
          <tbody>
            {gestionCodes.map((code) => (
              <tr key={code.id}>
                <td className="border p-2">{code.id}</td>
                <td className="border p-2">{code.value}</td>
                <td className="border p-2 text-center">
                  <button
                    onClick={() => handleDelete(code.id)}
                    className="bg-red-500 text-white p-1 rounded text-sm"
                    disabled={isLoading}
                  >
                    Supprimer
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminGestion;
