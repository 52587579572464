import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../utils/config";

export const getExports = createAsyncThunk(
  "export/get",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/export`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${data}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCsvInfo = createAsyncThunk(
  "getCsvInfo/post",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/export/csv/getNoteInfo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }

);



const ExportSlice = createSlice({
  name: "Exports",
  initialState: {
    exports: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getExports.pending]: (state, action) => {
      state.loading = true;
    },
    [getExports.fulfilled]: (state, action) => {
      state.exports = action.payload.exports;
      state.loading = false;
    },
    [getExports.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});


export default ExportSlice.reducer;
