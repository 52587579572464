import React, { useEffect, useState } from "react";
import axios from "axios";
import { REACT_APP_API_URL } from "../utils/config";
import { useSelector } from "react-redux";

function GlobalSearchBar({ showSearchBar, setSearchBar }) {
  const [input, setInput] = useState("");

  useEffect(() => {
    document.getElementById("search").focus();
  }, []);
  const auth = useSelector((state) => state.auth);

  // axios fetch with keyword body
  const fetchSearch = async () => {
    try {
      const header = {
        Authorization: `Bearer ${auth.user.api_token}`,
      };
      const body = { keyword: input };
      const res = await axios.post(`${REACT_APP_API_URL}/notes/filter`, body, {
        headers: header,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (input.length > 2) {
      fetchSearch();
    }
  }, [input]);

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-gray-900 opacity-50 z-10"></div>
      <div className="w-full max-w-screen-xl mx-auto px-6 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40">
        <div className="flex justify-center p-4 px-3 py-10">
          <div className="w-full max-w-md">
            <div className="bg-white shadow-md rounded-lg px-3 py-2 mb-4">
              <div className="block text-gray-700 text-lg font-semibold py-2 px-2">
                Chercher
              </div>
              <div className="flex items-center bg-gray-200 rounded-md">
                <div className="pl-2">
                  <svg
                    className="fill-current text-gray-500 w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className="heroicon-ui"
                      d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                    />
                  </svg>
                </div>
                <input
                  className="w-full rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none py-2 px-2"
                  id="search"
                  type="text"
                  placeholder="Search teams or members"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
              </div>
              <div className="py-3 text-sm">
                <div className="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                  <span className="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
                  <div className="flex-grow font-medium px-2">Tighten Co.</div>
                  <div className="text-sm font-normal text-gray-500 tracking-wide">
                    Team
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GlobalSearchBar;
