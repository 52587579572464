import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotes, getNotesByPage } from "../../app/reducers/Notes";
import { logout } from "../../app/reducers/Auth";
import MobileLinear from "../Dashboard/Graph/MobileLinear";
import { Link, useLocation } from "react-router-dom";
import { noteTypes } from "../../utils/utils";
import {
  FaPlane,
  FaQuestion,
  FaWifi,
  FaClock,
  FaCar,
  FaParking,
  FaTaxi,
} from "react-icons/fa";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { IoIosBed } from "react-icons/io";
import { GiPathDistance } from "react-icons/gi";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { IoRestaurant } from "react-icons/io5";
import { FaMobile } from "react-icons/fa";
import { FaTrain } from "react-icons/fa6";
import { SiTransportforlondon } from "react-icons/si";
import { FaRoad } from "react-icons/fa";
import ReactPaginate from "react-paginate";

function Home() {
  const notes = useSelector((state) => state.notes);
  const auth = useSelector((state) => state.auth);
  const [showPlusButton, setShowPlusButton] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getNotes(auth.user.api_token))
      .unwrap()
      .catch((err) => {
        dispatch(logout());
      });
  }, [dispatch, auth.user.api_token, location]);

  // getNotes with navigation

  const icons = {
    FaPlane,
    FaQuestion,
    FaWifi,
    FaClock,
    FaCar,
    FaParking,
    FaTaxi,
    BsFillFuelPumpFill,
    IoIosBed,
    GiPathDistance,
    BsFillPersonVcardFill,
    IoRestaurant,
    FaMobile,
    FaTrain,
    SiTransportforlondon,
    FaRoad,
  };

  const handlePageClick = (event) => {
    dispatch(
      getNotesByPage({ token: auth.user.api_token, page: event.selected + 1 })
    );
  };

  window.onscroll = function () {
    const threshold = 100; // pixels of leeway
    const scrolledHeight =
      window.innerHeight + document.documentElement.scrollTop;
    const totalHeight = document.documentElement.offsetHeight;

    if (scrolledHeight + threshold >= totalHeight) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  };

  return (
    <div className="w-screen mt-10">
      {/* <NotesTableMobile /> */}
      <div className="w-full flex justify-center">
        <h1 className="px-3 py-1.5 rounded-lg text-lg">
          <span className="bg-[#4286f6] text-white p-1 font-bold rounded">
            Aqua
          </span>{" "}
          Notes
        </h1>
      </div>
      <div className="w-full flex justify-center">
        <div className="p-4 flex flex-col border border-gray-200 rounded-xl dark:border-neutral-700">
          <div className="text-center">
            <p className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
              {notes &&
              notes.notes &&
              notes.notes.length &&
              notes.pagination &&
              notes.pagination.monthly_totals &&
              notes.pagination.monthly_totals[new Date().getMonth()]
                ? notes.pagination.monthly_totals[new Date().getMonth()]
                : 0}
              €
            </p>
          </div>
          <div className="stat-desc">
            {new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).toLocaleDateString(undefined, {
              month: "long",
              day: "numeric",
            })}
            -
            {new Date().toLocaleDateString(undefined, {
              month: "long",
              day: "numeric",
            })}
          </div>
        </div>
      </div>
      {/* <div className="mt-6 w-full">
        <MobileLinear />
      </div> */}
      <div className="w-full mt-4">
        <h1 className="font-bold text-xl m-2">Vos dernières notes</h1>

        {notes && notes.notes && notes.notes.length > 0 ? (
        <div>
          {notes.notes.map((note) => {
            const noteType = noteTypes.find(
              (type) => type.generique === note.TypeDepense
            ) || { icon: 'FaQuestion', couleur: '#000000' }; // Valeur par défaut
            const Icon = icons[noteType.icon] || FaQuestion; // Utilisation de FaQuestion comme icône par défaut

            return (
              <div className="note" key={note.id}>
                <Link to={`/note/${note.id}`}>
                  <div className="flex justify-between p-4 mx-2 shadow-sm">
                    <div className="flex">
                      <div
                        style={{ backgroundColor: noteType.couleur }}
                        className="p-1 rounded-lg"
                      >
                        <Icon className="m-auto h-full text-white" size={40} />
                      </div>
                      <div className="flex flex-col pl-2">
                        <div className="font-bold rounded-lg text-left">
                          {note?.Entreprise}
                        </div>
                        <div>
                          {new Date(note?.DateDepense).toLocaleDateString(
                            "fr-FR"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className=" pt-2">
                      <div className="font-bold text-lg text-right">
                        {note?.MontantDepense} €
                      </div>
                      <div
                        className="font-bold text-md text-center text-white rounded-lg p-1"
                        style={
                          note?.refunds.status === "En cours"
                            ? { backgroundColor: "#f59e0b" }
                            : note?.refunds.status === "Remboursé"
                            ? { backgroundColor: "#10b981" }
                            : { backgroundColor: "#ef4444" }
                        }
                      >
                        {note?.refunds.status}
                      </div>
                    </div>
                  </div>
                </Link>
                <hr className="w-10/12 m-auto" />
              </div>
            );
          })}
        </div>
        ) : (
          <div className="flex justify-center items-center h-20 font-medium  m-auto ">
            <p className="text-gray-400">Aucune note à afficher</p>
          </div>
        )}

        {/* If it need pagination */}
        { notes && notes.notes && notes.notes.length > 0 && notes.pagination && notes.pagination.last_page > 1 && (
        <ReactPaginate
          breakLabel="..."
          previousLabel={
            <li>
              <a
                href="/"
                className="inline-flex w-12 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
              >
                <span className="sr-only">Prev Page</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          }
          nextLabel={
            <li>
              <a
                href="/"
                className="inline-flex w-12 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
              >
                <span className="sr-only">Next Page</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={notes.pagination.last_page}
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          activeClassName={"item active"}
          breakClassName={"item break-me"}
          pageClassName={"item"}
        />
        )}
      </div>

      {/* Plus icon bottom right */}
      {showPlusButton && (
        <div className="fixed bottom-0 right-0 m-4">
          <Link to="/add-note">
            <button className="bg-[#4286f6] text-white p-4 rounded-full shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 m-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 4v16m8-8H4" strokeWidth="2" />
              </svg>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Home;
