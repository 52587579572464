export const noteTypes = [
  { generique: "Avion", couleur: "#51b065", icon: "FaPlane", name: "avion" },
  {
    generique: "Carburant",
    couleur: "#4b96dc",
    icon: "BsFillFuelPumpFill",
    name: "carburant",
  },
  {
    generique: "Divers",
    couleur: "#e08232",
    icon: "FaQuestion",
    name: "divers",
  },
  { generique: "Hotel", couleur: "#f2ca3c", icon: "IoIosBed", name: "hotel" },
  {
    generique: "Internet",
    couleur: "#932ab0",
    icon: "FaWifi",
    name: "internet",
  },
  {
    generique: "Intervention",
    couleur: "#de3061",
    icon: "FaClock",
    name: "intervention",
  },
  {
    generique: "Kilometrage",
    couleur: "#4b96dc",
    icon: "FaRoad",
    name: "kilometrage",
  },
  {
    generique: "Location Véhicule",
    couleur: "#51b065",
    icon: "FaCar",
    name: "locationvehicule",
  },
  {
    generique: "Parking",
    couleur: "#4b96dc",
    icon: "FaParking",
    name: "parking",
  },
  {
    generique: "Péage",
    couleur: "#30618f",
    icon: "BsFillFuelPumpFill",
    name: "peage",
  },
  {
    generique: "Réstauration",
    couleur: "#f2ca3c",
    icon: "IoRestaurant",
    name: "restauration",
  },
  { generique: "Taxi", couleur: "#51b065", icon: "FaTaxi", name: "taxi" },
  {
    generique: "Téléphone",
    couleur: "#932ab0",
    icon: "FaMobile",
    name: "telephone",
  },
  { generique: "Train", couleur: "#51b065", icon: "FaTrain", name: "train" },
  {
    generique: "Transport",
    couleur: "#51b065",
    icon: "SiTransportforlondon",
    name: "transport",
  },
];
