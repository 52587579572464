import React from "react";
import LoginPage from "./components/Auth/LoginPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Dashboard from "./components/Dashboard/Dashboard";
import AuthGuard from "./components/Auth/AuthGuard";

import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminMiddleware from "./components/Auth/AdminMiddleware";
import AdminUserPage from "./components/Admin/AdminUserPage";
import ExportPage from "./components/Dashboard/Export/ExportPage";
import RegisterPage from "./components/Auth/RegisterPage";
import AdminUserNotes from "./components/Admin/AdminUserNotes";
import AdminGestion from "./components/Admin/AdminGestion.jsx";
function RouterManager() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route element={<AuthGuard />}>
          <Route path="/" element={<Dashboard />} />
        </Route>

        {/* Admin Route */}
        <Route element={<AdminMiddleware />}>
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/gestion" element={<AdminGestion />} />
          <Route path="/users" element={<AdminUserPage />} />
          <Route path="/export" element={<ExportPage />} />
          <Route path="/admin/user/:id" element={<AdminUserNotes />} />
        </Route>
        {/* Default Route */}
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default RouterManager;
