import React from "react";
import LoginPage from "./components/Auth/LoginPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AuthGuard from "./components/Auth/AuthGuard";

import Home from "./components/Mobile/Home";
import MobileNoteViewer from "./components/Mobile/MobileNoteViewer";
import MobileNoteTypeSelector from "./components/Mobile/MobileNoteTypeSelector";
import MobileCreateNoteForm from "./components/Mobile/MobileCreateNoteForm";

function RouterMobileManager() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<AuthGuard />}>
          <Route path="/" element={<Home />} />
          <Route path="/note/:noteid" element={<MobileNoteViewer />} />
          <Route path="/add-note" element={<MobileNoteTypeSelector/>} />
          <Route path="/create-note/:notetype" element={<MobileCreateNoteForm />} />
        </Route>

        {/* Default Route */}
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default RouterMobileManager;
