import React from "react";
import { REACT_APP_STORAGE_URL } from "../../utils/config";

function SeeNotesModal({ selectedNote }) {
  return (
    <dialog id="modalSeeNote" className="modal">
      {selectedNote ? (
        <>
          <div className="modal-box max-w-3xl p-8 bg-gradient-to-br from-blue-50 to-blue-50 rounded-xl shadow-2xl border border-blue-200">
            <h2 className="text-3xl font-bold mb-6 text-blue-800 border-b-2 border-blue-300 pb-2">{selectedNote.Entreprise}</h2>
            <div className="grid grid-cols-2 gap-6 mb-8">
              <InfoItem label="Moyen de paiement" value={selectedNote.MoyenPaiement} />
              <InfoItem label="Date de dépense" value={selectedNote.DateDepense} />
              <InfoItem label="Type de dépense" value={selectedNote.TypeDepense} />
              <InfoItem label="Montant" value={`${selectedNote.MontantDepense} €`} />
            </div>
            <div className="border-4 border-blue-200 rounded-lg overflow-hidden shadow-lg">
              <img
                src={`${REACT_APP_STORAGE_URL}/${selectedNote.PathImageNote}`}
                alt="Note de frais"
                className="w-full object-cover"
              />
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button className="btn btn-circle btn-ghost absolute right-4 top-4 text-blue-600 bg-blue-100 hover:bg-blue-200 transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </form>
        </>
      ) : (
        <div className="p-8 text-center text-blue-600 font-semibold">Aucune note sélectionnée</div>
      )}
    </dialog>
  );
}

function InfoItem({ label, value }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md border border-blue-100">
      <span className="font-semibold text-blue-600 block mb-1">{label}</span>
      <span className="text-gray-800">{value}</span>
    </div>
  );
}

export default SeeNotesModal;
