import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../app/reducers/Auth";
import { Link, useNavigate } from "react-router-dom";

// Import des logos
import logo_ltf from "../../assets/Images/Logo_Entreprises/logo_ltf.png";
import logo_business from "../../assets/Images/Logo_Entreprises/logo_business.png";
import logo_discount from "../../assets/Images/Logo_Entreprises/logo_discount.png";
import logo_tomatocart from "../../assets/Images/Logo_Entreprises/logo_tomat.png";
import logo_trial from "../../assets/Images/Logo_Entreprises/logo_trial.png";
import logoDark from "../../assets/Images/logo_dark.png";

function RegisterPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [agence_id, setAgence_id] = useState("");

  const handleRegister = (event) => {
    event.preventDefault();
    dispatch(register({ email, name, password, code, agence_id }))
      .unwrap()
      .then(() => {
        navigate("/");
      });
  };

  return (
    <div className="bg-white flex justify-center items-center h-screen">
      {/* Partie gauche avec le formulaire d'inscription */}
      <main className="w-full max-w-md mx-auto p-8 bg-gradient-to-b from-gray-50 to-white rounded-3xl shadow-2xl">
        <div className="space-y-8">
          {/* Logo */}
          <div className="flex items-center justify-center">
            <img src={logo_ltf} alt="Logo" className="w-48 transition-all duration-300 hover:scale-105" />
          </div>

          <form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-all duration-300"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nom</label>
              <input
                type="text"
                id="name"
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-all duration-300"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Mot de passe</label>
              <input
                type="password"
                id="password"
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-all duration-300"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code AquaNotes</label>
              <input
                type="password"
                id="code"
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-all duration-300"
                required
                onChange={(e) => setCode(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="agence" className="block text-sm font-medium text-gray-700">Agence</label>
              <select
                id="agence"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md transition-all duration-300"
                required
                onChange={(e) => setAgence_id(e.target.value)}
                value={agence_id}
              >
                <option value="">Sélectionnez une agence</option>
                <option value="1">Tomat fermetures</option>
                <option value="2">Aqua fermetures</option>
                <option value="3">Aqua discount</option>
                <option value="4">Aqua business</option>
                <option value="5">Trial</option>
              </select>
            </div>

            {auth.error && (
              <p className="text-red-600 mt-2 text-sm">
                {Object.entries(auth.error).map(([field, errors]) => (
                  errors.map((error, index) => (
                    <p key={`${field}-${index}`}>{error}</p>
                  ))
                ))}
              </p>
            )}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300"
                onClick={handleRegister}
              >
                Créer un compte
              </button>
            </div>
          </form>

          <p className="mt-2 text-sm text-gray-600 text-center">
            Vous avez déjà un compte ?{" "}
            <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
              Se connecter
            </Link>
          </p>
        </div>
      </main>

      {/* Partie droite avec fond flouté et logos */}
      <div className="w-1/3 h-screen hidden lg:block relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-blue-700 opacity-75 backdrop-filter backdrop-blur-lg"></div>

        {/* Logos d'entreprise */}
        <div className="relative z-10 flex flex-col justify-center items-center h-full">
          <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-4 rounded-xl">
            <img src={logo_discount} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logo_tomatocart} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logo_trial} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logoDark} alt="Logo" style={{ width: "18rem" }} className="my-8"/>
            <img src={logo_business} alt="Logo" style={{ width: "12rem" }} className="m-auto"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
