import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../utils/config";

export const getNotes = createAsyncThunk(
  "notes/get",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/notes`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${data}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNotesByPage = createAsyncThunk(
  "notes/getByPage",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_URL}/notes?page=${data.page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNotesByFilter = createAsyncThunk(
  "notes/getByFilter",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_URL}/notes/filter/${data.search}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteNote = createAsyncThunk(
  "notes/delete",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/notes/${data.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addNote = createAsyncThunk("notes/add", async (data, thunkAPI) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}/notes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data.note),
    });

    const responseData = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(responseData);
    }
    return responseData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateNote = createAsyncThunk(
  "notes/update",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/notes/${data.note.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        body: JSON.stringify(data.note),
      });

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const AuthSlice = createSlice({
  name: "notes",
  initialState: {
    notes: [],
    pagination: {},
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getNotes.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getNotes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notes = action.payload.data.data;
      state.pagination = action.payload.data;
      state.error = null;
    },
    [getNotes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteNote.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteNote.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notes = state.notes.filter(
        (note) => note.id !== parseInt(action.payload.id)
      );
      state.error = null;

    },
    [deleteNote.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getNotesByPage.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getNotesByPage.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notes = action.payload.data.data;
      state.pagination = action.payload.data;
      state.error = null;

    },
    [getNotesByPage.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getNotesByFilter.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getNotesByFilter.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notes = action.payload.data.data;
      state.pagination = action.payload.data;
      state.error = null;

    },
    [getNotesByFilter.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [addNote.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addNote.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [addNote.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateNote.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateNote.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [updateNote.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

  },
});

export default AuthSlice.reducer;
