export const REACT_APP_VERSION = '1.0.3';

let REACT_APP_API_URL;
let REACT_APP_STORAGE_URL;

if (process.env.NODE_ENV === 'production') {
    REACT_APP_API_URL = `${window.location.protocol}//${window.location.hostname}/api`;
    REACT_APP_STORAGE_URL = `${window.location.protocol}//${window.location.hostname}/api/api/storage`;
} else {
    // Development Configuration
    const isMobileDev = process.env.REACT_APP_MOBILE_DEV === 'true';
    const devBaseUrl = isMobileDev ? 'http://192.168.56.1:8000' : 'http://127.0.0.1:8000';
    REACT_APP_API_URL = `${devBaseUrl}/api`;
    REACT_APP_STORAGE_URL = `${devBaseUrl}/storage`;
}

export { REACT_APP_API_URL, REACT_APP_STORAGE_URL };
