import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { FaWrench } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import EditUserModal from "../Modal/EditUserModal";
import { deleteUser } from "../../app/reducers/Users";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AdminUserList() {
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [modalEditUserOpen, setModalEditUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const deleteUserCall = (id) => {
    // comfirm alert
    const confirm = window.confirm("Voulez vous supprimer cet utilisateur ?");
    if (!confirm) return;
    dispatch(deleteUser({ id: id, token: auth.user.api_token }))
      .unwrap()
      .then(() => {
        toast.success("Utilisateur supprimé avec succès");
      })
      .catch((err) => {
        toast.error("Erreur lors de la suppression de l'utilisateur");
      });
  };

  const handleRowClick = (userId) => {
    navigate(`/admin/user/${userId}`);
  };

  return (
    <>
      {selectedUser && (
        <EditUserModal
          modalIsOpen={modalEditUserOpen}
          setIsOpen={setModalEditUserOpen}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}

      <div className="overflow-hidden border border-gray-200  rounded-[20px] mt-4">
        <div className="overflow-x-auto ">
          <div className="w-full h-full m-auto">
            <div className="bg-white rounded">
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr className=" text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6">Nom</th>
                    <th className="py-3 px-6">Email</th>
                    <th className="py-3 px-6">Nombre de notes</th>
                    <th className="py-3 px-6">Date de création</th>
                    <th className="py-3 px-6">Role</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {users.users &&
                    users.users.map((user) => (
                      <tr
                        className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                        key={user.id}
                        onClick={() => handleRowClick(user.id)}
                      >
                        <td
                          className="py-3 px-6 text-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="flex items-center">
                            <span className="font-medium m-auto">
                              {user.name}
                            </span>
                          </div>
                        </td>
                        <td className="py-3 px-6 text-center">
                          <div className="flex items-center">
                            <span className="m-auto font-medium">
                              {user.email}
                            </span>
                          </div>
                        </td>
                        <td className="py-3 px-6 text-center">
                          <div className="flex items-center">
                            <span className="m-auto font-medium">
                              {user.total_notes}
                            </span>
                          </div>
                        </td>
                        <td className="py-3 px-6 text-center">
                          <div className="flex items-center">
                            <span className="m-auto font-medium">
                              {new Date(user.created_at).toLocaleDateString(
                                "fr-FR"
                              )}
                            </span>
                          </div>
                        </td>
                        <td className="py-3 px-6 text-center">
                          <div className="flex items-center">
                            <span className="m-auto font-medium">
                              {user.role.role}
                            </span>
                          </div>
                        </td>

                        <td className="py-3 px-6 text-center">
                          {/* Voir,Modifier,Supprimer */}
                          <div className="flex item-center justify-center gap-4">
                            <div
                              className="bg-yellow-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedUser(user);
                                setModalEditUserOpen(true);
                              }}
                            >
                              <FaWrench size={20} color="white" />
                            </div>
                            <div
                              className="bg-red-400 rounded-lg p-2 hover:cursor-pointer hover:scale-105"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteUserCall(user.id);
                              }}
                            >
                              <BsFillTrashFill size={20} color="white" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {users.users.length === 0 && (
                    <tr>
                      <td colSpan="100%" className="text-center py-3 px-6">
                        Aucun user
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "right",
            width: "100%",
            height: "100%",
            margin: "auto",
          }}
        >
          <ReactPaginate
            breakLabel="..."
            previousLabel={
              <li>
                <a
                  href="#"
                  className="inline-flex w-12 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
                >
                  <span className="sr-only">Prev Page</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            }
            nextLabel={
              <li>
                <a
                  href="#"
                  className="inline-flex w-12 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
                >
                  <span className="sr-only">Next Page</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            }
            //   onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={users.pagination.last_page}
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            disabledClassName={"disabled-page"}
            activeClassName={"item active"}
            breakClassName={"item break-me"}
            pageClassName={"item"}
          />
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
}

export default AdminUserList;
