import React from "react";
import Sidebar from "../../SideBar";
import ExportTable from "./ExportTable";
import ExportForm from "./ExportForm";

function ExportPage() {
  return (
    <div className="flex bg-[#f4f7ff] h-full">
      <Sidebar />
      <div className="w-full mx-9">
        <h1 className="text-md mt-4 ">Pages / Export</h1>
        <h1 className="text-3xl mt-1 font-bold">Export Notes</h1>
        <div>
          <ExportForm />
        </div>
        <div className="my-3 ">
          <ExportTable />
        </div>
      </div>
    </div>
  );
}

export default ExportPage;
