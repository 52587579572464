// import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet,Navigate } from "react-router-dom";

const AuthGuard = () => {

	const auth = useSelector((state) => state.auth);

	return ( auth.isAuthenticated ? <Outlet /> : <Navigate to="/login" /> );

};

export default AuthGuard;