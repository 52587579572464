import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../utils/config";

export const getGestionCodes = createAsyncThunk(
  "gestionCodes/get",
  async (token, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/gestioncode`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addGestionCode = createAsyncThunk(
  "gestionCodes/add",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/gestioncode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        body: JSON.stringify({ value: data.value }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateGestionCode = createAsyncThunk(
  "gestionCodes/update",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/gestioncode/${data.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        body: JSON.stringify({ value: data.value }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteGestionCode = createAsyncThunk(
  "gestionCodes/delete",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/gestioncode/${data.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });

      const responseData = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const GestionCodesSlice = createSlice({
  name: "gestionCodes",
  initialState: {
    codes: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getGestionCodes.pending]: (state) => {
      state.isLoading = true;
    },
    [getGestionCodes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.codes = action.payload;
      state.error = null;
    },
    [getGestionCodes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [addGestionCode.pending]: (state) => {
      state.isLoading = true;
    },
    [addGestionCode.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.codes.push(action.payload);
      state.error = null;
    },
    [addGestionCode.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateGestionCode.pending]: (state) => {
      state.isLoading = true;
    },
    [updateGestionCode.fulfilled]: (state, action) => {
      state.isLoading = false;
      const index = state.codes.findIndex((code) => code.id === action.payload.id);
      if (index !== -1) {
        state.codes[index] = action.payload;
      }
      state.error = null;
    },
    [updateGestionCode.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteGestionCode.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteGestionCode.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.codes = action.payload;
      state.error = null;
    },
    [deleteGestionCode.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default GestionCodesSlice.reducer;
