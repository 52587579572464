import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoIosInformationCircle } from "react-icons/io";

function MobileHeader({ title }) {

    const getBackPage = () => {
        window.history.back();
    }

  return (
    <div className="w-full p-4 bg-[#4286f6]">
      <div className="flex justify-between items-center h-full">
        <IoMdArrowRoundBack className="text-white text-2xl" onClick={getBackPage} />
        <h1 className="text-white text-xl">{title}</h1>
        {/* For Placement purposes */}
        <IoIosInformationCircle className="text-white text-2xl" />
      </div>
    </div>
  );
}

export default MobileHeader;
